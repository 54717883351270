import './footer.scss'

export default function Footer() {
  return (
    <div className='footer'>
      <h1>Disclaimer*</h1>
      <div className="container">
        <p>The information offered on this site is not, and is not intended to be, legal advice. It is recommended that you consult an attorney for advice regarding your individual situation. If you need to contact us, we welcome your calls, letters and electronic mail. However, contacting us does not create an attorney-client relationship. An attorney-client relationship is not created until the firm is formally engaged by written agreement signed by the parties.
            Please do not send any confidential information to this office until such time as an attorney-client relationship has been established. Sending this firm an email or communicating with the firm in any means will not make you a client of Barreau Legal, PLLC; thus nothing you send will be confidential or privileged. Accordingly, please do not send us any confidential information by email or any other means except at the specific request of an attorney of this firm. Unsolicited emails or communications are not binding on this firm and cannot impose valid deadlines.
          </p>
      </div>
      <div className="copyright">
        <p>Copyright © 2024 Barreau Legal, PLLC | Powered by Verasoni</p>
      </div>
    </div>
  )
}
