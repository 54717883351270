import './Areas.scss';
import {AccountBalanceOutlined, Favorite, ContentCopyOutlined, MilitaryTechOutlined, Medication, Diversity1Rounded} from '@mui/icons-material';


export default function Areas() {
  return (
    <div className='areas' id='areas'>
       <h3>PROVIDING SUPPORT IN</h3>
        <h1>Legal Practice Areas</h1>
          <div className="container">
            <div className='topcard'>
              <div className='go'>
                  <AccountBalanceOutlined className="icon" />
                  <h6>ESTATE PLANNING</h6>
                  <p>The process of arranging your assets in a way so that they pass to your loved ones upon your death according to your wishes and setting the ground rules during your incapacity.</p>
                </div>
                <div className='go'>
                  <Favorite className="icon" />
                  <h6>TRUST ADMINISTRATION</h6>
                  <p>Handling of documents which can hold ownership over your assets.</p>
                </div>
                <div className='go'>
                <ContentCopyOutlined className="icon" />
                  <h6>PROBATION ADMINISTRATION</h6>
                  <p>The court process of transferring your loved one’s property to his or her appropriate heirs.</p>
                </div>

              </div>
              <div className='bottomcard'>
              <div className='go'>
              <Medication className="icon" />
                  <h6>MEDICAID PLANNING</h6>
                  <p>Helping you qualify for Medicaid benefits.</p>
                </div>
                <div className='go'>
                <MilitaryTechOutlined className="icon" />
                  <h6>VA BENEFIT PLANNING</h6>
                  <p>Helping you qualify for VA Benefits.</p>
                </div>
                <div className='go'>
                <Diversity1Rounded className="icon" />
                  <h6>SPECIAL NEEDS PLANNING</h6>
                  <p>Crafting a plan to help you safeguard the benefits of those with special needs.</p>
                </div>

              </div>
          </div>
    </div>
      
  )
}
