import "./topcontact.scss"
import {LocalPhoneOutlined, AccessTimeOutlined} from '@mui/icons-material/';

export default function Topcontact() {
  return (
    <div className="topContact">
      <div className="wrapper">
        <div className="left">
          <div className="phone">
          <LocalPhoneOutlined className="icon"/>
          <span>+1 682 239 4999</span>
          <span>supportdesk@blegalfl.site</span>
          </div>
          <div className="time">
          <AccessTimeOutlined className="icon"/>
          <span>Mon - Fri, 8:30am - 5:00pm (by appointment only) </span>
          </div>
        </div>
      </div>
    </div>
  )
}
