import  './topbar.scss';


export default function Topbar({menuOpen, setMenuOpen}) {
  return (
    <div className={"topbar " + (menuOpen && "active")}>
      <div className="wrapper">
        <div className="left">
          <a href="/" className='logo'>
            <img src="https://blegalfl.com/wp-content/uploads/2021/12/Barreau-Legal-Final-Logo-1-1-1536x710.png" alt="" width="30px" />
          </a>
        </div>
        <div className="center">  
            <div className="nav">
            <ul>
                <li>
                    <a href="#welcome">About Us</a>
                </li>
                <li>
                    <a href="#areas">Practice Areas</a>
                </li>
                <li>
                    <a href="#welcome">News & Articles</a>
                </li>
                <li>
                    <a href="#contact">Contact Us</a>
                </li>
            </ul>

            </div>
        </div>
        
        <div className="right">
          <div className="hamburger" onClick={() =>setMenuOpen(!menuOpen)}>
            <span className='line1'></span>
            <span className='line2'></span>
            <span className='line3'></span>
          </div>
        </div>
         
      </div>

    </div>
  )
}
