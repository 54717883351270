import "./menu.scss"
import { CloseOutlined } from '@mui/icons-material';

export default function Menu({ menuOpen, setMenuOpen }) {
  return (
    <div className={"menu " +(menuOpen && "active")}> 
    <div className="top" onClick={() => setMenuOpen(false)} >
        <CloseOutlined className="icon"/>
        </div>   
        <div className="bottom">
        <ul>
            <li onClick={() => setMenuOpen(false)}>
                <a href="#welcome">About Us</a>
            </li>
            <li onClick={() => setMenuOpen(false)}>
                <a href="#areas">Practice Areas</a>
            </li>
            <li onClick={() => setMenuOpen(false)}>
                <a href="#welcome">News & Articles</a>
            </li>
            <li onClick={() => setMenuOpen(false)}>
                <a href="#contact">Contact Us</a>
            </li>
        </ul>
        </div>
    </div>
  )
}
