import  './welcome.scss';

export default function Welcome() {
  return (
    <div className='welcome' id="welcome">
      <div className="container">
        <div className="top">
          <h2>WELCOME TO</h2>
          <h1>BARREAU LEGAL</h1>
          <p>Barreau Legal, PLLC is your partner in preserving generational wealth and beyond.</p>
          <button>contact us</button>
        </div>
        <div className="bottom">
        <div className="left">
          <h3>ABOUT BARREAU LEGAL</h3>
          <p>Raised in his hometown of Port St. Lucie, attorney Jamy Barreau has experience handling all aspects of issues surrounding preserving generational wealth including matters associated with trusts and estates as well as business planning.</p>
          <a href="#about" className='btn'>About Us</a>
        </div>


          <div className="right">
            <img src="assets/fam.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}
