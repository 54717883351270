import './contact.scss'

export default function Contact() {
  return (
    <div className='contact' id='contact'>
      <h1>Contact Us</h1>
      <h4>+1 682 239 4999</h4>
          <h4>supportdesk@blegalfl.site</h4>
      <div className="wrapper">
        <div className="info">
          
        </div>
        <div className="form">
        <div className="name">
        <div className="fname">
        <input className='fname' type="text"  placeholder='First Name'/>
        </div>
        <div className="lname">
        <input className='lname' type="text"  placeholder='Last Name'/>
        </div>
        </div>
        <div className="number">
        <input type="tel"  placeholder='Phone Number'/>
        </div>
        <div className="email">
        <input type="email"  placeholder='Email'/>
        </div>
        <div className="message">
        <textarea name="msg"  placeholder='Message'></textarea>
        </div>
        <div className="submit">
          <input type="button" value="Submit" />
        </div>
        </div>

      </div>
    </div>
  )
}
