import Areas from './components/areas/Areas'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import Topbars from './components/topbar/Topbar'
import Welcome from './components/welcome/Welcome'
import Topcontact from './components/topcontact/Topcontact'
import Testimonials from './components/testimonials/Testimonials'
import "./app.scss"
import { useState } from "react";
import Menu from "./components/menu/Menu"

function App() {
  const [menuOpen,setMenuOpen] = useState(false)
  return (
    <div className="app">
    <Topcontact/>
    <Topbars menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
    <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
    <div className="sections">
      <Welcome/>
      <Areas/>
      <Testimonials/>
      <Contact/>
      <Footer/>

    </div>
    
    </div>
  );
}

export default App;